<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
            <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
          <el-form-item label='是否有奖' prop='isprize' :label-width="formLabelWidth">
              <el-select v-model="form.isprize" placeholder="请选择">
                  <el-option
                          v-for="item in prizeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='兑换码' prop='excode' :label-width="formLabelWidth" v-if="form.isprize=='1'">
              <el-select v-model="form.excode" placeholder="请选择">
                  <el-option
                          v-for="item in excodeList"
                          :key="item.excode"
                          :label="item.name"
                          :value="item.excode">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='奖品名称' prop='name' :label-width="formLabelWidth">
              <el-input placeholder='请输入奖品名称' clearable autocomplete="off" v-model='form.name' style="width: 300px;"/>
          </el-form-item>
          <el-form-item label='奖品时长' prop='timelength' :label-width="formLabelWidth" v-if="form.isprize=='1'">
              <el-input placeholder='请输入奖品时长' clearable autocomplete="off" v-model='form.timelength' style="width: 300px;"/>
          </el-form-item>
            <el-form-item label='中奖权重' prop='weight' :label-width="formLabelWidth">
                <el-input-number placeholder='请输入中奖权重' clearable autocomplete="off" v-model='form.weight' :precision="0" :step="1" :min="1"></el-input-number>
                <el-tooltip class="item" effect="dark" content="权重越大中奖概率越高" placement="right">
                    <el-button type="text" style="margin-left: 10px;" icon="el-icon-question"></el-button>
                </el-tooltip>
            </el-form-item>

            <el-form-item label='状态' prop='status' :label-width="formLabelWidth">
                <el-select v-model="form.status" placeholder="请选择">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertPrizepool,updatePrizepool,deletePrizepool,bacthDelPrizepool,getExcodeList } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const prizeOptions=[
          {
              value:"1",
              label:"是"
          },
          {
              value:"0",
              label:"否"
          },
      ]
      const statusOptions=[
          {
              value:"1",
              label:"启用"
          },
          {
              value:"0",
              label:"禁用"
          },
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'name',label: '奖品名称'},
          {prop: 'timelength',label: '奖品时长'},
          {prop: 'isprize',label: '是否有奖',formatter: row => this.prizeState(row)},
          {prop: 'weight',label: '中奖权重'},
          {prop: 'excode',label: '兑换码'},
          {prop: 'code.name',label: '兑换码名称'},
          {prop: 'status',label: '状态',formatter: row => this.statusState(row)},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'name',
            label: '奖品名称',
            el: {placeholder: '请输入奖品名称', clearable: true},
          },
          {
            type: 'select',
            id: 'isprize',
            label: '是否有奖',
            el: {placeholder: '请选择是否有奖', clearable: true},
            options:prizeOptions
          },
          {
            type: 'input',
            id: 'excode',
            label: '兑换码',
            el: {placeholder: '请输入兑换码', clearable: true},
          },
          {
              type: 'select',
            id: 'status',
            label: '状态',
            el: {placeholder: '请输入状态', clearable: true},
            options:statusOptions
          },
      ]
      return {
        columns,
        statusOptions,
        prizeOptions,
        excodeList:[],
        goodsList:[],
        skuList:[],
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'prizepool/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('prizepool:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('prizepool:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('prizepool:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('prizepool:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            name :[
              {required: true, message: '请输入奖品名称', trigger: 'blur'},],
            timelength :[
                {required: true, message: '请输入奖品时长', trigger: 'blur'},],
            isprize :[
              {required: true, message: '请输入是否有奖', trigger: 'blur'},],
            weight :[
              {required: true, message: '请输入中奖权重', trigger: 'blur'},],
            excode :[
              {required: true, message: '请输入兑换码', trigger: 'blur'},],
            status :[
              {required: true, message: '请输入状态', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
      prizeState(row){
          let data
          this.prizeOptions.forEach(r=>{
              if (r.value==row.isprize){
                  data=r.label
              }
          })
          return data
      },
      statusState(row){
            let data
            this.statusOptions.forEach(r=>{
                if (r.value==row.status){
                    data=r.label
                }
            })
            return data
      },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          name : "", // 奖品名称
          timelength:"",
          isprize : "", // 是否有奖
          weight : "", // 中奖权重
          excode : "", // 兑换码
          status : "", // 状态
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deletePrizepool(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelPrizepool(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.form.isprize=='0'){
            this.form.excode=""
            this.form.timelength=""
        }
        if (this.type == "insert") {
          res = await insertPrizepool(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updatePrizepool(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getExcodeListInfo() {
            let res = await getExcodeList()
            if (res.status == "200") {
                this.excodeList=res.data
            } else {
                MessageError(res.msg)
            }
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('prizepool:update')&&!this.hasRole('prizepool:delete')){
              this.tableConfig.hasOperation=false
        }
        this.getExcodeListInfo()
      }
    }
  }
</script>

<style scoped>

</style>
